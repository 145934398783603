@import url('https://fonts.googleapis.com/css2?family=Lato&family=Open+Sans&family=Roboto+Condensed:wght@300&family=Roboto:wght@500&family=Source+Sans+Pro&family=Barlow:wght@500&display=swap');

html, body {
  background-color: #050723;
  color: #FFFFFF;
  margin: 0;
  & button , a {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
  }
  & h1, h2, label {
    font-family: 'Lato', sans-serif;
  }
  & p {
    font-family: 'Source Sans Pro', sans-serif;
  }
}
