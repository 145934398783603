.MetamaskContainer {
    position: relative;
    background-size: cover;
    background-position: center bottom;
    &__header {
        width: 100vw;
    }
    &__flags {
        position: absolute;
        top: 3.8%;
        left: 45%;
    }
    &__navbar {
        margin-left: 8%;
        margin-top: -25px;
    }
    &__previous {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 50px 0; 
        & h2 {
            color: #FFFFFF;
            & span {
                color: #FFCA7A;
            }
        }
        & p {
            width: 90%;
            line-height: 30px;
        }
    }
    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 100px;
        &-pre {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 600px;
            padding-top: 325px;
            margin-right: -30px;
            &-text {
                width: 350px;
                font-size: 14px;
                color: #FFCA7A;
                text-align: end;
                line-height: 30px;
                & a {
                    color: #FFCA7A;
                }
            }
        }
        &-post {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 600px;
            margin-left: -30px;
            padding-top: 45px;
            &-steps {
                padding-top: 25px;
            }
            &-text {
                width: 450px;
                font-size: 14px;
                color: #FFCA7A;
                line-height: 30px;
                & a {
                    font-family: 'Source Sans Pro', sans-serif !important; 
                    color: #FFCA7A;
                }
            }
        }
        &-number {
            width: 100px;
            &:nth-child(3n) {
                padding-top: 10px;
              }
        }
        &-fox {
            width: 140px;
        }
    }
    &__mobileMenu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 85px;
        &-burgerMenu {
            background-color: transparent;
            border: none;
            padding-left: 20px;
            & img {
                width: 20px;
            }
        }
        &-headerMobile {
            border-top: 1px solid #F1D195;
            width: 100vw;
            margin-top: -15px;
        }   
        &-navbar {
            display: flex;
            flex-direction: column;
            position: absolute;
            z-index: 10;
            top: 70px;
            background-color: #7B87B0;
            height: 100vh;
            width: 80%;
        }
    }
    &__contentMobile {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 20px 20px 200px 20px;
        &-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 600px;
            padding-top: 43px;
            margin-right: -30px;
            & h2 {
                margin-left: 20px;
            }
            &-text {
                width: 70%;
                font-size: 12px;
                color: #FFCA7A;
                text-align: start;
                line-height: 30px;
                & a {
                    color: #FFCA7A;
                }
            }
            &-steps {
                padding-top: 25px;
                margin-left: -10px;
                & img {
                    width: 50px;
                    margin-right: 10px;
                }
            }
            &-step {
                display: flex;
            }
        }
        &-number {
            width: 100px;
            &:nth-child(3n) {
                padding-top: 10px;
              }
        }
        &-fox {
            width: 120px;
            margin-top: 20px;
        }
    }
}

@media (min-width: 1024px) {
    .MetamaskContainer {
        &__previous {
            & p {
                width: 55%;
            }
        }
    }
}
@media (min-width: 1536px) {
    .MetamaskContainer {
        &__flags {
            position: absolute;
            top: 4.8%;
            left: 45%;
        }
    }
}