.GoldValueContainer {
    position: relative;
    background-size: cover;
    background-position: center bottom;
    padding-bottom: 100px;
    &__header {
        width: 100vw;
    }
    &__flags {
        position: absolute;
        top: 5.5%;
        left: 45%;
    }
    &__navbar {
        margin-left: 8%;
        margin-top: -25px;
    }
    &__mobileMenu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 85px;
        &-burgerMenu {
            background-color: transparent;
            border: none;
            padding-left: 20px;
            & img {
                width: 20px;
            }
        }
        &-headerMobile {
            border-top: 1px solid #F1D195;
            width: 100vw;
            margin-top: -15px;
        }   
        &-navbar {
            display: flex;
            flex-direction: column;
            position: absolute;
            z-index: 10;
            top: 70px;
            background-color: #7B87B0;
            height: 100vh;
            width: 80%;
        }
    }
}

@media (min-width: 1024px) {
    .GoldValueContainer {
        padding-bottom: 0px;
    }
}

@media (min-width: 1536px) {
    .GoldValueContainer {
        &__flags {
            top: 6.5%;
          }
        padding-bottom: 0px;
    }
}