// Colors
$white: #ffffff;
$grey3: #1a202d;
$gold1: #d4af34;
$darkblue: #061121;

.Chart {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 25px;
    height: 80vh;
    &-search {
      margin-top: 20px;
    }
    &-selectDates {
      width: 100%;
    }
    &-chart {
      width: 100%;
      & button {
        margin: 40px 0 60px;
      }
    }
  }
  & h1,
  p {
    color: #ffffff;
  }
  & h1 {
    & span {
      color: #ffca7a;
    }
  }
  & p {
    width: 100%;
    margin-bottom: 30px;
  }
  & input {
    background-color: $darkblue;
    color: #ffffff !important;
    border: 1px solid #cdcdcd;
    border-radius: 10px;
    margin-right: 20px;
    padding: 10px 15px;
    text-transform: uppercase;
    height: fit-content;
  }
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
  & button {
    background-color: #cdcdcd;
    color: #192d62;
    text-decoration: none;
    width: fit-content;
    border-radius: 10px;
    border: none;
    padding: 12px 20px;
    font-weight: 600;
    cursor: pointer;
  }
  .select__dates {
    position: absolute;
  }
}

.mobile {
  &:before {
    content: 'Inicio';
  }
  width: 110px;
  height: 35px !important;
}
.mobile2 {
    &:before {
      content: 'Fin';
    }
    width: 100px;
    height: 35px !important;
  }

@media (min-width: 1024px) {
  .Chart {
    &__container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 200px 0 100px;
      height: 100%;
      &-search {
        margin-top: 0px;
      }
      &-chart {
        width: 40%;
        & button {
          margin: 0;
          margin-bottom: 20px;
        }
      }
      &-selectDates {
        width: 35%;
      }
    }
    & p {
      width: 400px;
    }
    & button {
      &:last-child {
        margin: 0;
      }
    }
  }
}
@media (min-width: 1536px) {
}
