.ContactContainer {
  position: relative;
  background-size: cover;
  background-position: center bottom;
  &__content {
    padding: 50px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    background-image: url('./img/bgContact.png');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 85%;
    &-post {
      width: 100%;
      padding-left: 0px;
      display: flex;
      justify-content: flex-start;
      margin-right: 100px;
      &__feedback {
        justify-content: center !important;
        margin-right: 0px !important;
      }
    }
  }
  &__feedback {
    display: flex;
    flex-direction: column;
    align-items: center;
    & img {
      width: 50px;
    }
    & p {
      text-transform: uppercase;
      color: #6c95ff;
      text-align: center;
    }
  }
}

.FormContact {
  width: 100%;
  margin: 0;
  height: fit-content;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  & div {
    display: flex;
    justify-content: flex-end;
    width: 65%;
  }
  &__label {
    font-weight: 300;
    font-size: 16px;
    color: #6c95ff;
    padding-bottom: 20px;
    margin: 20px 10px 0 10px;
    &-country {
      margin-top: 10px !important;
    }
  }
  & input {
    border: 1px solid #6c95ff;
    border-radius: 10px;
    padding: 8px 16px;
    background-color: transparent;
    color: #ffffff;
    margin: 10px 0;
    width: 148px;
  }
  & select {
    background-color: #6c95ff;
    border: 1px solid #6c95ff;
    border-radius: 10px;
    width: 180px;
    height: 40px;
    color: #ffffff;
    padding-left: 10px;
  }
  &__textArea {
    background-color: transparent;
    border: 1px solid #6c95ff;
    border-radius: 10px;
    width: 175px;
    color: #ffffff;
  }
  ::placeholder {
    color: #6c95ff;
    font-weight: 300;
    font-size: 12px;
  }

  &__labelCV {
    position: relative;
    border: 1px solid #6c95ff;
    border-radius: 10px;
    height: 44px;
    padding: 10px 140px 10px 16px;
    color: #6c95ff;
    font-weight: 300;
    font-size: 12px;
    align-self: center;
    margin-left: 10px;
  }

  #file-input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    width: 330px;
  }

  &__button {
    padding: 10px 20px;
    cursor: pointer;
    margin: 50px 0 0 10px;
    border: none;
    border-radius: 10px;
    background-color: #4e6cee;
    color: #091337;
  }
}

@media (min-width: 1024px) {
  .FormContact {
    width: 50%;
    margin: 20px 0 0 50px;
    & div {
      width: 50%;
    }
    & input {
      width: 225px;
    }
    & select {
      width: 280px;
    }
    &__textArea {
      width: 273px;
    }
    &__labelCV {
      padding: 10px 215px 10px 16px;
    }
  }
  .ContactContainer {
    &__content {
      padding: 100px 0 0;
      background-size: 65%;
      &-post {
        padding-left: 150px;
        padding-right: 0;
        &__feedback {
          margin-right: 100px !important;
        }
      }
    }
  }
}

@media (min-width: 1536px) {
  .FormContact {
    & div {
        width: 40%;
      }
    & input {
      width: 248px;
    }
  }
}
