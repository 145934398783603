.FooterContainer {
  width: 100vw;
  color: #FFFFFF;
  text-align: left;
  &__bar {
    display: flex;
    justify-content: center;
    padding-top: 200px;
    & img {
      width: 80vw;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px;
  }
  &__firstCol {
    &-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      & img {
        width: 300px;
        margin-left: -55px;
      }
      & p {
        font-size: 24px;
        color: #e8af5e;
        margin-left: -50px;
        margin-top: 30px;
      }
    }
    &-select {
      padding: 15px 20px;
      width: 80%;
      border: 1px solid #0543b2;
      border-radius: 10px;
      margin-bottom: 10px;
      margin-top: -50px;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-image: url('./img/arrowDown.png');
      background-repeat: no-repeat, repeat;
      background-position: right 0.7em top 50%, 0 0;
      background-size: 0.95em auto, 100%;
      outline: none;
    }
    &-copyrigth,
    &-url,
    &-rights {
      font-family: 'Roboto';
      font-weight: 200;
      font-size: 20px;
    }
  }
  &__secondCol,
  &__thirdCol,
  &__fourthCol {
    padding-top: 20px;
    font-family: 'Roboto';
    font-weight: 400;
    & p {
      font-size: 20px;
    }
    & ul {
      list-style: none;
      padding: 0;
    }
    & li {
      font-size: 16px;
      padding-bottom: 5px;
      cursor: pointer;
      & a {
        text-decoration: none;
        color: #FFFFFF;
      }
    }
  }
}

@media (min-width: 1024px) {
  .FooterContainer {
    &__content {
      flex-direction: row;
      padding: 50px 200px;
    }
    &__secondCol,
  &__thirdCol,
  &__fourthCol {
    padding-top: 90px;
  }
  }
}

@media (min-width: 1536px) {
  .FooterContainer {
    &__content {
      padding: 50px 300px;
    }
  }
}
