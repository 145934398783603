.ButtonAccess {
  position: static;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;
  width: 17%;
  &__wallet {
    width: 75%;
    height: 110%;
  }
}

@media (min-width: 1024px) {
  .ButtonAccess {
    position: absolute;
    top: 0;
    right: 5%;
    display: flex;
    justify-content: flex-end;
    width: 17%;
    &__wallet {
      width: 35%;
    }
  }
}
