.WhoWeAreContainer {
    &__content{
        margin-top: 100px;
        display: flex;
        justify-content: center;
        &-div {
          max-width: 80%;
          & h2 {
            font-family: 'Roboto', sans-serif;
            color: #343a85;
            font-size: 16px;
          }
          & h3 {
            font-family: 'Barlow', sans-serif;
            color: #FFFFFF;
            font-size: 24px;
          }
          & p {
            font-family: 'Roboto', sans-serif;
            margin-bottom: 20px;
            line-height: 25px;
            color: #FFFFFF;
            font-size: 16px;
          }
        }
      }
}

.BlockchainContainer {
    position: relative;
    background-size: cover;
    background-position: center bottom;
    &__header {
        width: 100vw;
    }
    &__flags {
        position: absolute;
        top: 4%;
        left: 45%;
    }
    &__navbar {
        margin-left: 8%;
        margin-top: -25px;
    }
    & h1 {
        text-align: center;
        margin: 50px 0;
    }
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px 20px 100px 20px;
        &-card {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            border: 1px solid #005FFE;
            border-radius: 14px;
            padding: 10px 40px;
            background-color: #081338;
            & h2 {
                font-size: 24px;
                color: #FFCC5A;
            }
            & img {
                width: 100px;
                min-height: 150px;
                margin-right: 40px;
            }
            & div {
                width: 250px;
            }
            &-text0, &-text1, &-text2 {
                color: #94B8FB;
                font-size: 12px;
            }
            &-text0 {
                padding-top: 0px;
            }
            &:not(:first-child) {
                margin-left: 0px;
                margin-top: 20px;
            }
        }
    }
    &__mobileMenu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 85px;
        &-burgerMenu {
            background-color: transparent;
            border: none;
            padding-left: 20px;
            & img {
                width: 20px;
            }
        }
        &-headerMobile {
            border-top: 1px solid #F1D195;
            width: 100vw;
            margin-top: -15px;
        }   
        &-navbar {
            display: flex;
            flex-direction: column;
            position: absolute;
            z-index: 10;
            top: 70px;
            background-color: #7B87B0;
            height: 100vh;
            width: 80%;
        }
    }
}

@media (min-width: 1024px) {
    .WhoWeAreContainer {
        &__content {
            &-div {
              max-width: 50%;
            }
        }
    }
    .BlockchainContainer {
        &__content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 0 0 100px;
            &-card {
                flex-direction: column;
                padding: 20px;
                & img {
                    margin-right: 0px;
                }
                &:not(:first-child) {
                    margin-left: 25px;
                    margin-top: 0px;
                }
                &-text0, &-text1, &-text2 {
                    font-size: 16px;
                }
                &-text0 {
                    padding-top: 30px;
                }
            }
        }
    }
}

@media (min-width: 1536px) {
    .BlockchainContainer {
        &__flags {
            top: 6.2%;
          }
    }
}