.Navbar {
    &-rest {
        margin-left: 0px;
        &__who {
            min-width: 120px;
        }
    }
    &-home, &-rest {
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 24px;
        font-weight: 300;
        border: 1px solid #F1D195;
        border-radius: 0 15px 15px 0;
        width: 80%;
        margin: 5px 0;
        padding: 10px 0 10px 10px;
        background-color: #001B49;
        text-decoration: none;
        cursor: pointer;
    }
}

.desktopActive {
    color: #5E7EFF;
}

.desktopInactive {
    color: #FFFFFF;
}

.mobileActive {
    background-color: #5E7EFF;
    color: #001B49 !important;
    font-weight: 500;
}

.mobileInactive {
    color: #5E7EFF !important;
    background-color: #001B49;
}

@media (min-width: 1024px) {
    .Navbar {
        &-home, &-rest {
            border: none;
            border-radius: none;
            width: 100%;
            margin: 0;
            padding: 0;
            background-color: transparent;
        }
        &-home {
            text-decoration: none;
            font-size: 20px;
            font-weight: 300;
            cursor: pointer;
        }
        &-rest {
            text-decoration: none;
            margin-left: 50px;
            font-size: 20px;
            font-weight: 300;
            cursor: pointer;
        }
    }
}