.UserContainer {
  position: relative;
  background-size: cover;
  background-position: center bottom;
  &__header {
    width: 100vw;
  }
  &__flags {
    position: absolute;
    top: 5.5%;
    left: 45%;
  }
  &__navbar {
    margin-left: 8%;
    margin-top: -25px;
  }
  &__content {
    background-size: 85%;
    background-position: center top;
    background-repeat: no-repeat;
    margin-top: 100px;
    padding: 100px 0 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    &-panel {
      display: flex;
      flex-direction: column;
      padding-left: 10%;
    }
    &-rigth {
      &-address,
      &-balance {
        margin-bottom: 40px;
        & p {
          &:first-child {
            color: #7b9db6;
            font-weight: 600;
          }
          &:last-child {
            color: #b4bbcd;
          }
        }
      }
      &-operations {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &-title {
          color: #7b9db6;
          font-weight: 600;
          margin-top: 0;
        }
        & button {
          border: none;
          border-radius: 10px;
          display: flex;
          align-items: center;
          padding: 10px 20px 10px 10px;
          margin: 10px 0;
          cursor: pointer;
          & img {
            margin-right: 10px;
            width: 40px;
          }
        }
        &__btnLight {
          background-color: #e8af5e;
          color: #060723;
          cursor: pointer;
        }
        &__btnDark {
          background-color: #060723;
          color: #e8af5e;
          cursor: pointer;
        }
      }
    }
    &-left {
      padding-left: 0px;
      width: 100%;
      &-errors {
        color: #ce5b5b;
        font-size: 10px;
      }
      & label {
        margin-top: 18px;
        display: block;
      }
      & input {
        display: block;
        margin: 20px 0 5px;
        border: 1px solid #a0a8bd;
        border-radius: 10px;
        background-color: transparent;
        color: #ffffff;
        padding: 10px;
      }
      & button {
        background-color: #cdcdcd;
        color: #192d62;
        text-decoration: none;
        width: fit-content;
        border-radius: 10px;
        border: none;
        padding: 12px 20px;
        font-weight: 600;
        margin-top: 20px;
        cursor: pointer;
      }
    }
  }
  ::placeholder {
    color: #7b9db6;
  }
  &__mobileMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 85px;
    &-burgerMenu {
      background-color: transparent;
      border: none;
      padding-left: 20px;
      & img {
        width: 20px;
      }
    }
    &-headerMobile {
      border-top: 1px solid #f1d195;
      width: 100vw;
      margin-top: -15px;
    }
    &-navbar {
      display: flex;
      flex-direction: column;
      position: absolute;
      z-index: 10;
      top: 70px;
      background-color: #7b87b0;
      height: 100vh;
      width: 80%;
    }
  }
}

@media (min-width: 1024px) {
  .UserContainer {
    &__content {
      background-size: 1000px;
      align-items: center;
      &-panel {
        flex-direction: row;
        padding-left: 0%;
      }
      &-left {
        padding-left: 60px;
        width: 400px;
      }
    }
  }
}