.PurchaseContainer {
  position: relative;
  background-size: cover;
  background-position: center bottom;
  &__header {
    width: 100vw;
  }
  &__flags {
    position: absolute;
    top: 5%;
    left: 45%;
  }
  &__navbar {
    margin-left: 8%;
    margin-top: -25px;
  }
  &__content {
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    &-pre {
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-title {
        width: 100%;
        padding-left: 10%;
        & img {
          width: 200px;
          margin-left: -32px;
        }
      }
      &-img {
          width: 80%;
          margin-left: 0;
          margin-bottom: 40px;
      }
      & p {
        width: 90%;
        margin-bottom: 60px;
        color: #4e6cee;
        line-height: 25px;
        & span {
          color: #ffffff;
        }
      }
      & h2 {
        margin-bottom: -25px;
        font-size: 30px;
      }
    }
    &-post {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  &__mobileMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 85px;
    &-burgerMenu {
      background-color: transparent;
      border: none;
      padding-left: 20px;
      & img {
        width: 20px;
      }
    }
    &-headerMobile {
      border-top: 1px solid #f1d195;
      width: 100vw;
      margin-top: -15px;
    }
    &-navbar {
      display: flex;
      flex-direction: column;
      position: absolute;
      z-index: 10;
      top: 70px;
      background-color: #7b87b0;
      height: 100vh;
      width: 80%;
    }
  }
}

.Form {
  border: 1px solid #0173f8;
  border-radius: 8px;
  width: 320px;
  margin: 0;
  height: fit-content;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  &__label {
    font-weight: 300;
    font-size: 16px;
    color: #6c95ff;
    padding-bottom: 20px;
    margin: 20px 0 0 10px;
  }
  & input {
    border: 1px solid #6c95ff;
    border-radius: 10px;
    padding: 8px 16px;
    background-color: transparent;
    margin: 10px;
    color: #ffffff;
    width: 100px;
  }
  &__input {
    &-wallet {
      width: 255px !important;
    }
  }
  ::placeholder {
    color: #6c95ff;
    font-weight: 300;
    font-size: 12px;
  }

  &__labelCV {
    position: relative;
    border: 1px solid #6c95ff;
    border-radius: 10px;
    height: 44px;
    padding: 10px 140px 10px 16px;
    color: #6c95ff;
    font-weight: 300;
    font-size: 12px;
    align-self: center;
    margin-left: 10px;
  }

  #file-input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    width: 330px;
  }

  &__button {
    padding: 10px 20px;
    cursor: pointer;
    margin: 50px 0 0 10px;
    border: none;
    border-radius: 10px;
    background-color: #4e6cee;
    color: #091337;
  }
}

@media (min-width: 1024px) {
  .PurchaseContainer {
    &__content {
      margin-top: 100px;
      flex-direction: row;
      &-pre,
      &-post {
        width: 35%;
        margin: 0;
      }
      &-pre {
        & p {
          width: 500px;
        }
        &-img {
          width: 500px;
          margin-bottom: 0px;
        }
        &-title {
          padding-left: 0%;
        }
      }
    }
  }
  .Form {
    width: 386px;
    height: 510px;
    margin: 20px 0 0 50px;
    & input {
      width: 36%;
    }
    &__input {
      &-wallet {
        width: 330px !important;
      }
    }
    &__labelCV {
      padding: 10px 215px 10px 16px;
    }
  }
}

@media (min-width: 1536px) {
  .PurchaseContainer {
    &__flags {
      top: 6%;
    }
    &__content {
      &-pre {
        &-title {
          width: 75%;
        }
      }
    }
  }
}
