.HomeContainer {
  position: relative;
  &__header {
    width: 100vw;
    z-index: 2;
  }
  &__title {
    position: absolute;
    width: 75%;
    top: 24%;
    left: -8%;
    &-EN {
      position: absolute;
      width: 75%;
      top: 7%;
      left: -8%;
    }
  }
  &__navbar {
    display: flex;
    align-items: center;
    position: absolute;
    top: 15%;
    left: 8.5%;
    z-index: 10;
  }
  &__main {
    position: absolute;
    left: 8.5%;
    display: flex;
    align-items: flex-start;
    z-index: 10;
    &-box1 {
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 200px;
      width: 100%;
      margin-right: 50px;
      padding-bottom: 150px;
    }
    &-title {
      width: 300px;
      margin-left: -50px;
    }
    &-subtitle {
      margin-top: -20px;
      color: #3c54b8;
      font-family: 'Open Sans', sans-serif;
      font-size: 20px;
    }
    & h2 {
      font-family: 'Roboto', sans-serif;
      font-size: 32px;
    }
    &-text {
      padding-top: 10px;
      padding-bottom: 20px;
      font-size: 18px;
      line-height: 25px;
      width: 100%;
      font-family: 'Source Sans Pro', sans-serif;
    }
    &-btn {
      margin-top: 20px;
      text-decoration: none;
      color: #050723;
      background-color: #5269ea;
      padding: 10px 15px;
      border-radius: 12px;
    }
  }
  &__mainEnd {
    position: absolute;
    left: 5%;
    top: 900px;
    z-index: 5;
    & h2 {
      font-family: 'Roboto', sans-serif;
      font-size: 20px;
    }
  }
  &__bg {
    position: absolute;
    top: 100px;
    left: 0;
    & img {
      width: 100vw;
      margin-top: 0px;
      z-index: 0;
    }
  }
  &__footer {
    position: absolute;
    width: 100%;
    top: 1190px;
    text-align: center;
    color: #ffc853;
    & h2 {
      font-size: 32px;
    }
    &-cards {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      flex-wrap: wrap;
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: cover;
      padding-bottom: 100px;
    }
    &-card {
      border: 1px solid #005ffe;
      border-radius: 14px;
      padding: 30px 20px;
      width: 120px;
      & img {
        width: 100px;
        height: 100px;
        margin-bottom: 10px;
      }
      & p {
        font-size: 16px;
        width: 120px;
      }
      margin: 10px;
      &:not(:last-child) {
        margin-right: 0px;
      }
    }
  }
  &__mobileMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 85px;
    &-burgerMenu {
      background-color: transparent;
      border: none;
      padding-left: 20px;
      & img {
        width: 20px;
      }
    }
    &-headerMobile {
      border-top: 1px solid #f1d195;
      width: 100vw;
      margin-top: -15px;
    }
    &-navbar {
      display: flex;
      flex-direction: column;
      position: absolute;
      z-index: 15;
      top: 70px;
      background-color: #7b87b0;
      height: 100vh;
      width: 80%;
    }
  }
  &__originalFooter {
    background: linear-gradient(#060723, #1b1e45, #343a85);
  }
}

@media (min-width: 1024px) {
  .HomeContainer {
    &__main {
      &-box1 {
        width: 50vw;
        background-size: 360px;
        margin-right: 150px;
        padding-bottom: 50px;
      }
      &-text {
        width: 60%;
        padding-top: 20px;
        padding-bottom: 75px;
      }
    }
    &__mainEnd {
      left: 8.5%;
      top: 1050px;
      & h2 {
        font-size: 22px;
      }
    }
    &__bg {
      & img {
        margin-top: -100px;
      }
    }
    &__footer {
      top: 1600px;
      &-cards {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 50px;
        padding-bottom: 0px;
      }
      &-card {
        padding: 60px 40px;
        width: 200px;
        & img {
          width: 150px;
          height: 150px;
          margin-bottom: 20px;
        }
        & p {
          font-size: 20px;
          width: 180px;
        }
        &:not(:last-child) {
          margin-right: 25px;
        }
      }
    }
  }
}

@media (min-width: 1536px) {
  .HomeContainer {
    &__mainEnd {
      top: 1200px;
    }
    &__footer {
      top: 1850px;
    }
    &__bg {
      & img {
        margin-top: -150px;
      }
    }
  }
}

@media (min-width: 1836px) {
  .HomeContainer {
    &__bg {
      & img {
        margin-top: -400px;
      }
    }
  }
}
