.Flags {
  display: flex;
  &-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    & img {
      width: 40px;
    }
    &-left {
      margin-left: 0px;
    }
  }
}

@media (min-width: 1024px) {
  .Flags {
    &-btn {
      &-left {
        margin-left: 100px;
      }
    }
  }
}
