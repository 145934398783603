//Edit carousel styles
.carousel-slider {
  height: 700px;
}
.control-dots {
    margin-bottom: 100px !important;
}
.carousel .control-dots .dot {
  width: 10px;
  height: 10px;
  box-shadow: none !important;
  background-color: #A4AEFD;
  &:hover {
    border-radius: 50%;
  }
}
.carousel .control-dots .dot.selected {
  width: 10px;
  height: 10px;
  box-shadow: none !important;
  border-radius: 50%;
  background-color: #A4AEFD;
}

.SliderContainer {
    width: 23vw;
    color: #A4AEFD;
    &__box {
        margin-top: 150px;
        &-content {
            // width: 23%;
            text-align: end;
            line-height: 40px;
            margin-left: 35px;
            font-family: 'Open Sans', sans-serif !important;
        }
    }
}

@media (min-width: 1800px) {
    .SliderContainer {
        &__box {
            &-content{
                margin-left: 135px;
            }
        }
    }
    .control-dots {
        margin-left: 50px !important;
    }
}

